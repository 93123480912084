import React from 'react';
import { graphql } from 'gatsby';
import IndexPage from '../../components/IndexPage';

export const query = graphql`
  query {
    contentfulSeoData(node_locale: {eq: "th"}) {
      homeOgDescription {
        homeOgDescription
      }
      homeOgTitle
      ogImage {
        file {
          url 
          details {
            image {
              width
              height
            }
          }
        }
      }
    }

    contentfulSiteData(node_locale: {eq: "th"}) {
      selectLanguageText
      subtitle { subtitle }
      title {
        title
      }
      description {
        description 
      }
      logoImage {
        gatsbyImageData
      }
      participantsText
      startText
    }
  }
`;

const IndexThPage = ({ data }) => (
  <IndexPage data={data} lang="th" />
);

export default IndexThPage;
